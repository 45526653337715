// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCZ6HpNl7kZwevmiwnEcgf4RrnHO51LDtA",
  authDomain: "otter-and-seal.firebaseapp.com",
  projectId: "otter-and-seal",
  storageBucket: "otter-and-seal.appspot.com",
  messagingSenderId: "433587317721",
  appId: "1:433587317721:web:dd41293420b7d7f19047c8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;