import ApplicationShell from "../../Components/ApplicationShell";
import { useEffect, useState, Fragment, useCallback, useRef } from "react";
import { Menu, MenuButton, MenuItem, MenuItems, Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react'
import { 
    ArrowPathIcon,
    InformationCircleIcon,
    PhotoIcon,
    ExclamationTriangleIcon,
    ExclamationCircleIcon,
    XMarkIcon,
    CheckIcon,
    EllipsisVerticalIcon
  } from '@heroicons/react/24/outline';
import GlobalVars from "../../Config";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import app from '../../firebase';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { set } from "firebase/database";


const auth = getAuth(app);

export default function TicketList() {
    // set page title to Ticket History
    useEffect(() => {
        document.title = "Ticket History";
    }, []);

    const [page, setPage] = useState(1);

    // states for view more slide over
    const [openViewMore, setOpenViewMore] = useState(false);
    const [ticketId, setTicketId] = useState(null);

    // show submit message
    const [openSubmitMessage, setOpenSubmitMessage] = useState(false);
    const [success, setSuccess] = useState(false);  // true for success, false for error

    // filter query
    const [filterQuery, setFilterQuery] = useState({
        status: "",
        ticketHumanID: "",
    });

    return (
        <>
            <ApplicationShell>
                <PageHeading />
                <FilterQueryContainer filterQuery={filterQuery} setFilterQuery={setFilterQuery} />
                <TicketListContainer 
                  page={page} 
                  setPage={setPage} 
                  setOpenViewMore={setOpenViewMore}
                  setTicketId={setTicketId}
                  filterQuery={filterQuery}
                />
            </ApplicationShell>
            <ViewMoreSlideOver  
              open={openViewMore}
              setOpen={setOpenViewMore}
              ticketId={ticketId}
              setOpenSubmitMessage={setOpenSubmitMessage}
              setSuccess={setSuccess}
            />
            <SubmitMessageDialog open={openSubmitMessage} setOpen={setOpenSubmitMessage} success={success} />
        </>
        
    )
}

// Page heading
function PageHeading() {
    return (
      <div className="md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            Ticket History
          </h2>
        </div>
        <div className="mt-4 flex md:ml-4 md:mt-0">
          {/* <button
            type="button"
            className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            Edit
          </button> */}
          <button
            type="button"
            className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() => {
              window.location.href = "/submit";
            }}
          >
            Submit a Ticket
          </button>
        </div>
      </div>
    )
  }
  
// Ticket list 
function TicketListContainer({page, setPage, setOpenViewMore, setTicketId, filterQuery}) {
    const [tickets, setTickets] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchTickets(setTickets, setLoading, page, filterQuery);
    }, []);

    // set time interval of 5 seconds to refresh the ticket list
    useEffect(() => {
        const interval = setInterval(() => {
            fetchTickets(setTickets, setLoading, page, filterQuery);
        }, 5000);
        return () => clearInterval(interval);
    }, [filterQuery]);

    // set window scroll event to fetch more tickets
    const handleScroll = useCallback(() => {
        if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) return;
        setPage((prevPage) => prevPage + 1);
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);

    useEffect(() => {
        fetchTickets(setTickets, setLoading, page, filterQuery);
    }, [page, filterQuery]);

    // check if user is on mobile
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        if (window.innerWidth < 640) {
            setIsMobile(true);
        }
    }, [window.innerWidth]);
    

    return (
        <>  
            <div className="mt-8 flow-root" id="ticket-list-container">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                                <tr>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                    ID
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Status
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Updates
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Location 
                                </th>
                                {!isMobile && (
                                  <>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Product Type 
                                    </th>
                                    {/* <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                         Description
                                    </th> 
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                         Email
                                    </th>  */}
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Submitted
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Completed
                                    </th>
                                  </>
                                )}
                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                                    <span className="sr-only">Learn More</span>
                                </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                                {loading ? (
                                    <div
                                        className="flex items-center justify-center space-x-2 p-4"
                                    >
                                        <ArrowPathIcon className="animate-spin h-5 w-5 text-gray-500 mx-auto" />
                                        <span>Loading</span>
                                    </div>
                                ): (
                                    <>
                                        {tickets.map((item) => (
                                            <tr key={item._id}>
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                                    {item.humanReadableId}
                                                </td>
                                                <td className={`whitespace-nowrap px-3 py-4 text-sm ${item.status === 'Resolved' ? 'text-green-500' : 'text-gray-500'}`}>
                                                    {item.status}
                                                </td>
                                                <td className={`whitespace-nowrap px-3 py-4 text-sm`}>
                                                    <StatusUpdateDescription desc={item.statusUpdate} />
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 location-name">{item.locationName}</td>
                                                {!isMobile && (
                                                  <>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 location-name">{item.productType}</td>
                                                    {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 description-content">
                                                        {typeof item.remarks === 'string' && item.remarks.substring(0, 50)}
                                                    </td> */}
                                                    {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.meta && item.meta["email"]}</td> */}
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.createdAt}</td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{(item.lastUpdated && item.status == "Resolved") ? item.lastUpdated : "N/A"}</td>
                                                  </>
                                                )}
                                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                                    <div
                                                      className="flex items-center"
                                                    >
                                                      <a 
                                                          onClick={() => {
                                                              setOpenViewMore(true);
                                                              setTicketId(item._id);
                                                          }}
                                                          className="text-indigo-600 hover:text-indigo-900 cursor-pointer mr-2"
                                                      >
                                                          View More<span className="sr-only">, {item.name}</span>
                                                      </a>
                                                      <ActionDropdown itemId={item._id} setTickets={setTickets} setLoading={setLoading} />
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
         
        </>
    )
}

// fetch ticket data
const fetchTickets = (setTickets, setLoading, page=1, filterQuery={}) => {
    let email = localStorage.getItem('userEmail');

    let url = `${GlobalVars.BACKEND_DOMAIN}/api/v1/ticket?email=${email}&page=${page}`;

    // if filterQuery is not empty, add filterQuery as url argument
    if (filterQuery.hasOwnProperty("status")) {
      // check if status is not empty
      if (filterQuery.status !== "") {
        url += "&status=" + filterQuery.status;
      }
    }

    // add ticketID to url
    if (filterQuery.hasOwnProperty("ticketHumanID")) {
      // check if ticketID is not empty
      if (filterQuery.ticketID !== "") {
        url += "&ticketHumanID=" + filterQuery.ticketHumanID;
      }
    }

    fetch(url)
    .then(res => res.json())
    .then(data => {
        // check if data is a list, if not, return empty list
        if (!Array.isArray(data)) {
            data = [];
        }
        
        setTickets(data);
        setLoading(false);
    })
    .catch(err => {
        console.log(err);
    });
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

// status update description
function StatusUpdateDescription({desc}) {

    return (
      <Menu as="div" className="relative inline-block text-left">
        <div>
            <Menu.Button 
                className={`inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white py-2 text-sm   ring-gray-300 hover:bg-gray-50 ${(desc && desc != "" ? "text-gray-900": "text-gray-400")} `}
                // enable if there is a description
                disabled={(desc  && desc != "") ? false : true}
            >
                    {(desc  && desc != "") ? "View" : "No updates"}
                <InformationCircleIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
            </Menu.Button>
        </div>
  
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <div
                    className="block px-4 py-2 text-sm text-gray-700 whitespace-pre-wrap status-update-description"
                  >
                    <p>
                        {desc}
                    </p>
                  </div>
                )}
              </Menu.Item>
              
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    )
  }

// View more slide over 
function ViewMoreSlideOver({
  open, 
  setOpen, 
  ticketId,
  setOpenSubmitMessage,
  setSuccess,
}) {  

  // handle submission message
  const [formData, setFormData] = useState({
    messageBody: "This is a message from Golden News, your ticket has been resolved.",
    email: localStorage.getItem('userEmail'),
    preferredNotificationMedia: "sms",
    organizationID: "6555c512db20a8030affbfcc",
    ticketID: ticketId,
  });
  const [uploadFiles, setUploadFiles] = useState(null);

  const uploadBtn = useRef(null);
  const [isPending, setIsPending] = useState(false);
  const handleSubmissionMessage = () => {
    if (!isPending) {
      alert("To prevent confusion, only pending job can be edited. If you would like to edit this task, please contact our service hotline: (852) 8100 9019.");
      return
    }

    let submitBody = formData;
    // format check for email
    if (!submitBody.email || !submitBody.email.includes("@")) {
        alert("Please enter a valid email address");
        return;
    }

    // show error if users forgot to enter description
    if (!submitBody.description) {
        alert("Please enter a description");
        return;
    }

    // confirm users
    if (!window.confirm("Are you sure you want to upload this ticket?")) {
        return;
    } 

    // disable button and change text
    uploadBtn.current.disabled = true;
    uploadBtn.current.textContent = "Uploading...";

    let putUrl = GlobalVars.BACKEND_DOMAIN + "/api/v1/ticket";
    fetch(putUrl, {
        method: "PUT",
        body: JSON.stringify(submitBody),
        headers: {
            "Content-Type": "application/json",
        },
    })
    .then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            console.log(response.text());
            throw new Error("Error");
        }
    })
    .then((data) => {
        // return error if ticketID is not found
        if (!data.ticketID) {
            throw new Error("TicketID not found");
        }

        // return if there's no file to upload
        let files = [];
        if (!uploadFiles) {
            setOpenSubmitMessage(true);
            setSuccess(true);
            uploadBtn.current.disabled = false;
            uploadBtn.current.textContent = "Submit";
            return;
        } else {  // create a list of files with keys name and type
            for (let i = 0; i < uploadFiles.length; i++) {
                files.push({
                    name: uploadFiles[i].name,
                    type: uploadFiles[i].type,
                });
            }

        }           

        // fetch url for signed url
        let signedUrlPostUrl = GlobalVars.BACKEND_DOMAIN + "/api/v1/ticket-attachment";
        fetch(signedUrlPostUrl, {
          method: "POST",
          body: JSON.stringify({
            ticketID: data.ticketID,
            files: files,
            organizationID: formData.organizationID,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                console.log(response.text())
                throw new Error("Error getting signed URL");
            }
        })
        .then((data) => {
          let urls = data.signedUrls;
          // use signed url to upload file
          let uploadPromises = urls.map((url, index) => {
            return fetch(url, {
              method: "PUT",
              body: uploadFiles[index],
            })
            .then((response) => {
              if (response.ok) {
                console.log("File uploaded successfully");
                // show success message
                setOpenSubmitMessage(true);
                setSuccess(true);
              } else {
                throw new Error("Error uploading file");
              }
            });
          });

          Promise.all(uploadPromises)
            .finally(() => {
              uploadBtn.current.disabled = false;
              uploadBtn.current.textContent = "Save";
            });
        })
        .catch((error) => {
            console.error("Error:", error);

            uploadBtn.current.disabled = false;
            uploadBtn.current.textContent = "Submit";

            // show failure message
            setOpenSubmitMessage(true);
            setSuccess(false);
        });
    })
    .catch((error) => {
        console.error("Error:", error);

        uploadBtn.current.disabled = false;
        uploadBtn.current.textContent = "Submit";

        // show failure message
        setOpenSubmitMessage(true);
        setSuccess(false);
    })


  }

  return (
    <Transition show={open}>
      <Dialog className="relative z-50" onClose={setOpen}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <TransitionChild
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <DialogPanel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <DialogTitle className="text-base font-semibold leading-6 text-gray-900">
                            Edit Ticket Detail 
                          </DialogTitle>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                              onClick={() => setOpen(false)}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-6 flex-1 px-4 sm:px-6">
                        <FormContent 
                          ticketId={ticketId} 
                          setOpenSubmitMessage={setOpenSubmitMessage}
                          setSuccess={setSuccess}
                          formData={formData}
                          setFormData={setFormData}
                          uploadFiles={uploadFiles}
                          setUploadFiles={setUploadFiles}
                          setIsPending={setIsPending}
                        />
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      <button
                        type="button"
                        className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
                        onClick={() => setOpen(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                        onClick={handleSubmissionMessage}
                        ref={uploadBtn}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

// form content 
function FormContent({setOpenSubmitMessage, setSuccess, ticketId, formData, setFormData, uploadFiles, setUploadFiles, setIsPending}) {
    const [humanReadableId, setHumanReadableId] = useState("");

    // if ticketId is not null, fetch ticket data
    useEffect(() => {
      if (!ticketId) return;

      // clear existing list 
      setFormData({
        messageBody: "This is a message from Golden News, your ticket has been resolved.",
        email: localStorage.getItem('userEmail'),
        preferredNotificationMedia: "sms",
        organizationID: "6555c512db20a8030affbfcc",
        ticketID: ticketId,
      })

      // fetch ticket data
      fetch(GlobalVars.BACKEND_DOMAIN + "/api/v1/ticket?ticketID=" + ticketId)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error fetching ticket data");
        }
        return response.json()
      })
      .then((data) => {
        
        let ticketDetail = {
          locationID: data.locationID,
          locationName: data.locationName,
          description: data.remarks,
          customerPhoneForEnquiry: data["customerPhoneForEnquiry"],
          productType: data["productType"],
          email: data['meta']?.["email"] || "",
          name: data["meta"]?.["name"] || "",
          locationOption: {label: data.locationName, value: data.locationID},
          
        }
        
        if (data.hasOwnProperty("humanReadableId")) {
          setHumanReadableId(data.humanReadableId);
        }

        if (data.hasOwnProperty("visitTime")) {
          ticketDetail["visitTime"]= data['meta']["visitTime"]
        }

        // add contactInfo if property exists
        if (data.hasOwnProperty("contactInfo")) {
          ticketDetail["preferredNotificationMedia"] = data["contactInfo"]["medium"];
          ticketDetail["messageBody"] = data["contactInfo"]["content"];
          ticketDetail["contacts"] = data["contactInfo"]["contactList"];
        }

        setFormData(prevFormData => ({  ...prevFormData, ...ticketDetail }));

        if ('status' in data && data.status === "Pending") {
          setIsPending(true);
        } else {
          setIsPending(false);
        }

      })
      .catch((error) => {
        console.error("Error:", error);
      });
    }, [ticketId]);

    
    const [fileNames, setFileNames] = useState(null);
    const [pastFiles, setPastFiles] = useState(null); 
    useEffect(() => {
        // return if null
        if (!uploadFiles) return;
        
        setFileNames(Array.from(uploadFiles).map((file) => file.name));
    }, [uploadFiles]);
    

    // if ticketId is not null, fetch attachment list 
    useEffect(() => {
        if (!ticketId) return;

        let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/ticket-attachment?ticketId=" + ticketId;

        fetch(url)
        .then((response) => {
            if (!response.ok) {
                throw new Error("Error fetching attachment list");
            }
            return response.json();
        })
        .then((data) => {
            setPastFiles(data);
            
        })
        .catch((error) => {
            console.error("Error:", error);
        });

    }, [ticketId]);

    // handle delete past files 
    const handleDeletePastFiles = (id) => {
      console.log("Delete file with id: ", id);
      if (!window.confirm("Are you sure you want to delete this file?")) {
        return;
      }

      let submitBody = {
        "attachmentID": id
      }
      fetch(GlobalVars.BACKEND_DOMAIN + "/api/v1/ticket-attachment", {
        method: "DELETE",
        body: JSON.stringify(submitBody),
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.ok) {
          // remove the file from the list
          setPastFiles(pastFiles.filter((file) => file._id !== id));
          return response.json();
        } else {
          throw new Error("Error deleting file");
        }
      })

    }

    // get organizationID from url argument
    const [orgName, setOrgName] = useState("");
    let url = new URL(window.location.href);
    let org = url.searchParams.get("org");
    useEffect(() => {
        if (!org) return;

        fetch(GlobalVars.BACKEND_DOMAIN + "/api/v1/organization?organizationID=" + org)
        .then((response) => response.json())
        .then((data) => {
            setOrgName(data.name);
        });

    }, [org]);

    // get locationID from url argument
    const [locationName, setLocationName] = useState("Galaxy Far Far Away");
    const [locationOption, setLocationOption] = useState([]);
    const [disableLocationOption, setDisableLocationOption] = useState(false);
    let location = url.searchParams.get("loc");
    // useEffect(() => {
    //     // return if location is null
    //     if (!location) return;

    //     fetch(GlobalVars.BACKEND_DOMAIN + "/api/v1/location?locationID=" + location)
    //     .then((response) => response.json())
    //     .then((data) => {
    //         setLocationName(data.name);
    //     });
    // }, [location]);

    // handle file upload
    const uploadBtn = useRef(null);
    const handleFileUpload = (e) => {
        e.preventDefault();
        let submitBody = formData;

        // format check for email
        if (!formData.email || !formData.email.includes("@")) {
            alert("Please enter a valid email address");
            return;
        }


        // show error if users forgot to enter description
        if (!formData.description) {
            alert("Please enter a description");
            return;
        }

        // confirm users
        if (!window.confirm("Are you sure you want to upload this ticket?")) {
            return;
        } else {
        uploadBtn.current.disabled = true;
        uploadBtn.current.textContent = "Uploading...";
        }

        let postUrl = GlobalVars.BACKEND_DOMAIN + "/api/v1/ticket";
        fetch(postUrl, {
            method: "POST",
            body: JSON.stringify(submitBody),
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                console.log(response.text());
                throw new Error("Error");
            }
        })
        .then((data) => {
            // return error if ticketID is not found
            if (!data.ticketID) {
                throw new Error("TicketID not found");
            }

            // return if there's no file to upload
            let files = [];
            if (!uploadFiles) {
                setOpenSubmitMessage(true);
                setSuccess(true);
                uploadBtn.current.disabled = false;
                uploadBtn.current.textContent = "Submit";
                return;
            } else {  // create a list of files with keys name and type
                for (let i = 0; i < uploadFiles.length; i++) {
                    files.push({
                        name: uploadFiles[i].name,
                        type: uploadFiles[i].type,
                    });
                }

            }           

            // fetch url for signed url
            let signedUrlPostUrl = GlobalVars.BACKEND_DOMAIN + "/api/v1/ticket-attachment";
            fetch(signedUrlPostUrl, {
            method: "POST",
            body: JSON.stringify({
                ticketID: data.ticketID,
                files: files,
                organizationID: formData.organizationID,
            }),
            headers: {
                "Content-Type": "application/json",
            },
            })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    console.log(response.text())
                    throw new Error("Error getting signed URL");
                }
            })
            .then((data) => {
            let urls = data.signedUrls;
            // use signed url to upload file
            let uploadPromises = urls.map((url, index) => {
                return fetch(url, {
                method: "PUT",
                body: uploadFiles[index],
                })
                .then((response) => {
                if (response.ok) {
                    console.log("File uploaded successfully");
                    // show success message
                    setOpenSubmitMessage(true);
                    setSuccess(true);
                } else {
                    throw new Error("Error uploading file");
                }
                });
            });

            Promise.all(uploadPromises)
                .finally(() => {
                uploadBtn.current.disabled = false;
                uploadBtn.current.textContent = "Save";
                });
            })
            .catch((error) => {
                console.error("Error:", error);

                uploadBtn.current.disabled = false;
                uploadBtn.current.textContent = "Submit";

                // show failure message
                setOpenSubmitMessage(true);
                setSuccess(false);
            });
        })
        .catch((error) => {
            console.error("Error:", error);

            uploadBtn.current.disabled = false;
            uploadBtn.current.textContent = "Submit";

            // show failure message
            setOpenSubmitMessage(true);
            setSuccess(false);
        })
    }

    // retrieve users info using given email 
    useEffect(() => {
        if (!formData.email) return;

        fetch(GlobalVars.BACKEND_DOMAIN + "/api/v1/user?email=" + formData.email)
          .then((response) => response.json())
          .then((data) => {

              // setFormData(prevFormData => ({
              // ...prevFormData,
              // name: data.name,
              // userID: data.userID,
              // customerPhoneForEnquiry: data.phone,
              // }));

              // get locationOption with the given user id 
              let getUrl = GlobalVars.BACKEND_DOMAIN + "/api/v1/associated-location" + "?userID=" + data.userID;

              // if login users' email is @goldennews.com.hk using /api/v1/location
              if (formData.email.includes("@goldennews.com.hk")) {
                  getUrl = GlobalVars.BACKEND_DOMAIN + "/api/v1/location";
              }

              fetch(getUrl)
              .then((response) => response.json())
              .then((data) => {
                  if (data['message'] == 'Location not found') {
                      setDisableLocationOption(true);
                      setLocationOption([]);
                      return;
                  }

                  // filter out data where isActive exist and is false
                  data = data.filter((location) => {
                      return location.isActive !== false;
                  });

                  // if langVar exists and contain langVar.tc, add to the name
                  data = data.map((location) => {
                      if (location.langVar) {
                        if (location.langVar.tc) {
                          location.label = location.label + " (" + location.langVar.tc + ")";
                        }
                      } else {
                          location.label = location.label;
                      }
                      return location;
                  });



                  setLocationOption(data);

                  // check if locationIDValue is in formData. If yes, set default value for locationName
                  // if (formData.locationIDValue) {
                  //     setLocationName(data.filter((item) => item.value === formData.locationIDValue)[0].label);
                  //     setFormData({ ...formData, locationID: formData.locationIDValue });
                  // }
              });
          
          });

        
    }, [formData.email]);

    // retrieving signedurl for access file 
    const handleRetrievePastFiles = (id) => {
        // fetch signed url
        fetch(GlobalVars.BACKEND_DOMAIN + "/api/v1/ticket-attachment?fileId=" + id)
        .then((response) => {
            if (!response.ok) {
                throw new Error("Error fetching attachment list");
            }
            return response.json();
        })
        .then((data) => {
            if (!data.hasOwnProperty("url")) {
                throw new Error("Error fetching attachment list");
            }

            window.open(data.url, '_blank');
        })
        .catch((error) => {
            console.error("Error:", error);
        });
    }

    
    return (
        <form>
        <div className="space-y-12">
            <div className="border-gray-900/10 pb-12">
            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                {/* a disabled input field for humanReadableId  */}
                <div className="sm:col-span-full">
                    <div className="flex justify-between">
                        <label htmlFor="humanReadableId" className="block text-sm font-medium leading-6 text-gray-900">
                          Ticket ID
                        </label>
                    </div>
                    <div className="mt-2">
                        <input
                        type="text"
                        name="humanReadableId"
                        id="humanReadableId"
                        className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={humanReadableId}
                        disabled
                        />
                    </div>
                </div>
                
                <div className="sm:col-span-full">
                    <div className="flex justify-between">
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                        Email
                        </label>
                        <span className="text-sm leading-6 text-red-500" id="email-optional">
                        Required
                        </span>
                    </div>
                    <div className="mt-2">
                        <input
                        type="email"
                        name="email"
                        id="email"
                        className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="you@example.com"
                        value={formData.email}
                        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                        />
                    </div>
                </div>
                <div className="sm:col-span-full">
                    <div className="flex justify-between">
                        <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                        Name
                        </label>
                        <span className="text-sm leading-6 text-red-500" id="name-optional">
                        Required
                        </span>
                    </div>
                    <div className="mt-2">
                        <input
                        type="text"
                        name="name"
                        id="name"
                        className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={formData.name}
                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                        />
                    </div>
                </div>
                {/* add a location input but it's disabled */}
                <div className="sm:col-span-full">
                    <div className="flex justify-between">
                        <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-900">
                        Location
                        </label>
                    </div>
                    <div className="mt-2">
                        <CreatableSelect 
                          id="location-selector"
                          options={locationOption}
                          isDisabled={disableLocationOption}
                          value={formData.locationOption? formData.locationOption : null}
                          onChange={(e) => {
                              if (!e) {
                                setLocationName("");
                                setLocationOption([]);
                                setFormData({ ...formData, locationID: "", locationOption: null});
                                return;
                              } else {
                                setLocationName(e.label);
                                setFormData({ ...formData, locationID: e.value, locationOption: e });
                              }
                              
                          }}
                          isClearable
                        />
                    </div>
                </div>
                {/* add a number input for Client number optional for us to ask enquiry */}
                <div className="sm:col-span-full">
                    <div className="flex justify-between">
                        <label htmlFor="client-number" className="block text-sm font-medium leading-6 text-gray-900">
                        Client Contact Number
                        </label>
                    </div>
                    <div>
                    <span
                        className="text-sm leading-6 text-gray-600"
                    >Please provide a phone number where we can reach you for further inquiries related to your ticket.</span>
                    </div>
                    <div className="mt-2">
                        <input
                        type="number" 
                        name="client-number"
                        id="client-number"
                        className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={formData.customerPhoneForEnquiry}
                        onChange={(e) => setFormData({ ...formData, customerPhoneForEnquiry: e.target.value })}
                        />
                    </div>
                </div>
                {/* a dropdown menu for selection */}
                <div className="sm:col-span-full">
                <ProductTypeSelection setFormData={setFormData} formData={formData} />
                </div>

                <div className="col-span-full">
                    <div className="flex justify-between">
                        <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                        Description
                        </label>
                        <span className="text-sm leading-6 text-red-500" id="email-optional">
                        Required
                        </span>
                    </div>
                <div className="mt-2">
                    <textarea
                        id="description"
                        name="description"
                        rows={3}
                        className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={formData.description}
                        onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                    />
                </div>
                <p className="mt-3 text-sm leading-6 text-gray-600">Please provide all details above, including product and examples for us to troubleshoot, and we'll be in touch shortly.</p>
                </div>

    
                <div className="col-span-full">
                <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
                    Attach Files
                </label>
                <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                    <div className="text-center">
                    <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                    <div className="mt-4 flex text-sm leading-6 text-gray-600 justify-center">
                        <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                        >
                        <span>Upload files</span>
                        <input 
                            id="file-upload" 
                            name="file-upload" 
                            type="file" 
                            className="sr-only" 
                            multiple 
                            onChange={(e) => setUploadFiles(e.target.files)}
                        />
                        </label>
                        <p className="pl-1">or drag and drop</p>
                    </div>
                    <p className="text-xs leading-5 text-gray-600">PNG, JPG, XLSX, PDF, DOCX, PPTX, TXT up to 10MB</p>
                    </div>
                </div>
                {/* list out file names */}
                    {(pastFiles && pastFiles.length > 0) && (
                        <div className="mt-2">
                          <ul className="border border-gray-900/25 rounded-md divide-y divide-gray-900/25">
                              {pastFiles.map((file, index) => (
                              <li key={index} className="flex justify-between items-center px-3 py-2">
                                  <span className="text-sm leading-6 text-gray-900">{file.fileName}</span>
                                  <div>
                                    <button
                                      type="button"
                                      className="text-sm leading-6 text-indigo-600 mr-2"
                                      onClick={() => handleRetrievePastFiles(file._id)}
                                    >
                                      Download
                                    </button>
                                    <button
                                      type="button"
                                      className="text-sm leading-6 text-red-500"
                                      onClick={() => handleDeletePastFiles(file._id)}
                                    >
                                      Remove
                                    </button>
                                  </div>
                              </li>
                              ))}
                          </ul>
                        </div>
                    )}
                    {(fileNames && fileNames.length > 0) && (
                        <div className="mt-2">
                        <ul className="border border-gray-900/25 rounded-md divide-y divide-gray-900/25">
                            {Array.from(fileNames).map((file, index) => (
                            <li key={index} className="flex justify-between items-center px-3 py-2">
                                <span className="text-sm leading-6 text-gray-900">{file}</span>
                                <button
                                type="button"
                                className="text-sm leading-6 text-red-500"
                                onClick={() => {
                                    // update fileNames
                                    setFileNames(fileNames.filter((fileName) => fileName !== file));
                                }}
                                >
                                Remove
                                </button>
                            </li>
                            ))}
                        </ul>
                        </div>
                    )}
                </div>
                <hr
                    className="col-span-full border-gray-900/25"
                ></hr>
                <TitleContainer
                title={"Notification"}
                alertTitle={"SMS/Phone Notification"}
                alertMessage={"We will send you an alert when your ticket is resolved."}

                />
                <ContactInfoCont 
                formData={formData}
                setFormData={setFormData} 
                />

                <hr
                    className="col-span-full border-gray-900/25"
                ></hr>
                <TitleContainer
                title={"Work Arrangement"}
                />
                {/* a date and time input for staff to visit */}
                <div className="sm:col-span-full">
                    <div className="">
                        <label htmlFor="visit-time" className="block text-sm font-medium leading-6 text-gray-900">
                        Visit Time
                        </label>
                        <p
                        className="text-sm leading-6 text-gray-600"
                        >
                        For onsite task, if you would like the staff to visit at a specific date and time, please enter below.
                        </p>
                    </div>
                    <div className="mt-2">
                        <input
                        type="datetime-local"
                        name="visit-time"
                        id="visit-time"
                        className="px-2 bg-white block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={formData.visitTime}
                        onChange={(e) => setFormData({ ...formData, visitTime: e.target.value })}
                        />
                    </div>
                </div>

                {/* add an organization input but it's disabled */}
                {/* <div className="sm:col-span-4">
                    <div className="flex justify-between">
                        <label htmlFor="organization" className="block text-sm font-medium leading-6 text-gray-900">
                        Target Organization
                        </label>
                    </div>
                    <div className="mt-2">
                        <input
                        type="text"
                        name="organization"
                        id="organization"
                        className="bg-gray-100 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={orgName}
                        disabled
                        /> 
                    </div>
                </div> */}
            </div>
            </div>
        </div>
    
        {/* <div 
            className="mt-6 flex items-center justify-end gap-x-6 bg-indigo-50 w-full py-4 px-7"
            style={{position: "fixed", bottom: "0px", right: "0px"}}
        >
            <button
            type="button"
            className="duration-300 flex rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={handleFileUpload}
            ref={uploadBtn}
            >
            <PaperAirplaneIcon className="mr-1 h-5 w-5 text-white" aria-hidden="true" />
            Submit
            </button>
        </div> */}
        </form>
    )
}

// product type selection
function ProductTypeSelection({formData, setFormData}) {
  
    const [items, setItems] = useState(GlobalVars.productType);
    useEffect(() => {
      // pick the first item as the default item
      setFormData({ ...formData, productType: items[0]['value'] });
    }, []);
  
    return (
      <>
        <label htmlFor="product-type" className="mb-1 block text-sm font-medium leading-6 text-gray-900">
          Product Type
        </label>
        <Select
          options={items}
          value={formData.productType ? {label: formData.productType, value: formData.productType} : null}
          onChange={(e) => setFormData({ ...formData, productType: e.value })}
          isSearchable
        />
      </>
    )
  }

// Title container
function TitleContainer({title, alertTitle, alertMessage}) {
    return (
      <div
        className="col-span-full"
      >
        <h2 className="text-lg font-semibold leading-7 text-gray-900">{title}</h2>
        {alertTitle || alertMessage ? (
        <div className="rounded-md bg-yellow-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-yellow-800">{alertTitle}</h3>
              <div className="mt-2 text-sm text-yellow-700">
                <p>
                  {alertMessage}
                </p>
              </div>
            </div>
          </div>
        </div>
        ) : null}
      </div>
    )
  }

// contact information container
// Users need to enter preferred notification media, message content, and a list of contact
function ContactInfoCont({formData, setFormData}) {
    const handleAddContact = () => {  
      let newContacts = formData.contacts ? [...formData.contacts] : [];
      newContacts.push("98765432");
      setFormData({ ...formData, contacts: newContacts });
    }
  
  
    return (
      <>
      <div className="col-span-full">
        <label htmlFor="preferred-notification-media" className="block text-sm font-medium leading-6 text-gray-900">
          Preferred Notification Media
        </label>
        <div className="mt-2">
          <select
            id="preferred-notification-media"
            name="preferred-notification-media"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            value={formData.preferredNotificationMedia}
            onChange={(e) => setFormData({ ...formData, preferredNotificationMedia: e.target.value })}
          >
            <option value="sms">SMS</option>
            <option value="phone">Automated Phone Call</option>
          </select>
        </div>
        {/* message body */}
        <div className="mt-6">
          <label htmlFor="message-body" className="block text-sm font-medium leading-6 text-gray-900">
            Message Body
          </label>
          <div className="mt-2">
            <textarea
              id="message-body"
              name="message-body"
              rows={3}
              className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              value={formData.messageBody}
              onChange={(e) => setFormData({ ...formData, messageBody: e.target.value })}
            />
          </div>
        </div>
        {/* expandable contact list inputs */}
        <div
          className="mt-6"
        >
       
        <label
          htmlFor="contact-list"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Contact List
        </label>
        <p
          className="mt-1 text-sm leading-6 text-gray-600"
        >
          Please enter a list of contacts to receive notifications. The format must be 8 digits Hong Kong phone number.
        </p>
        {formData.contacts && formData.contacts.map((contact, index) => (
          <ContactInfo key={index} contact={contact} setFormData={setFormData} index={index} formData={formData} />
        ))}
        <div className="mt-2">
          <button
            type="button"
            className="text-sm leading-6 text-indigo-600"
            onClick={handleAddContact}
          >
            Add a New Contact
          </button>
          <button
            type="button"
            className="text-sm leading-6 text-red-500 ml-2"
            onClick={() => {
              let newContacts = formData.contacts;
              newContacts.pop();
              setFormData({ ...formData, contacts: newContacts });
            }}
          >
            Delete the Last Contact
          </button>
        </div>
        </div>
  
      </div>
  
      </>
    )
}
  
function ContactInfo({contact, setFormData, index, formData}) {
  const inputRef = useRef(null);
  // if users enter more than 8 digits, show an error message
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  useEffect(() => {
    // check if contact is a valid phone number
    if (contact.length > 8) {
      setError(true);
      setErrorMsg("Phone number must be 8 digits or less.");
    // must start with 2, 3, 5, 6, 9
    } else if (contact[0] != "5" && contact[0] != "6" && contact[0] != "9" && contact[0] != "2" && contact[0] != "3") {
      setError(true);
      setErrorMsg("Phone number must start with 2, 3, 5, 6, or 9.");
    } else {
      setError(false);
    }
  }, [contact]);

  return (
    <div className="col-span-full">
      <div className="flex justify-between">
        <label htmlFor="contact" className="block text-sm font-medium leading-6 text-gray-900">
          
        </label>
      </div>
      <div className="relative mt-2 rounded-md shadow-sm">
        <input
          type="number"
          name="contact"
          id="contact"
          className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          value={contact}
          ref={inputRef}
          onChange={(e) => {
            let newContacts = [...formData.contacts];
            newContacts[index] = e.target.value;
            setFormData({ ...formData, contacts: newContacts });
          }}
        />
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          {error && (
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          )}
        </div>
      </div>
      {error && (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {errorMsg}
        </p>
      )}
    </div>
  )
}

// Submit message
function SubmitMessageDialog({open, setOpen, success}) {

  const handleClose = () => {
    // check if users has logged in with firebase
    onAuthStateChanged(auth, (user) => {
      // if not successful, just dismiss the dialog
      if (!success) {  
        setOpen(false);
        return;
      }

      if (user) {
        // User is signed in.
        // redirect to the home page
        window.location.href = "/tickets";
      } else {
        // No user is signed in.
        // redirect to the login page
        window.location.href = "/";
      }
    });
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className={`mx-auto flex h-12 w-12 items-center justify-center rounded-full ${success? "bg-green-100" : "bg-red-100"}`}>
                    {success ? (
                      <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                    ) : (
                      <ExclamationCircleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                    )  
                    }
                    
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Ticket {success ? "submitted" : "not submitted"}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        {success ? "Your ticket has been submitted successfully." : "There was an error submitting your ticket."}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={handleClose}
                  >
                    Got it
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

// filter query container 
function FilterQueryContainer({filterQuery, setFilterQuery}) {
  const handleResetFilter = () => {
    setFilterQuery({
      ticketHumanID: "",
      status: "",
    });
  }

  return (
    <div
      className="col-span-full sm:flex sm:items-end mt-4"
    >
      {/* an input for searching id  */}
      <div className="sm:col-span-2">
        <label htmlFor="ticket-id" className="block text-sm font-medium leading-6 text-gray-900">
          Ticket ID
        </label>
        <div className="mt-2">
          <input
            type="text"
            name="ticket-id"
            id="ticket-id"
            className="h-8 px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            value={filterQuery.ticketHumanID}
            onChange={(e) => setFilterQuery({ ...filterQuery, ticketHumanID: e.target.value })}
          />
        </div>
      </div>
      {/* a dropdown for filtering status Pending, Resolved, Canceled */}
      <div className="sm:col-span-2 sm:ml-2 mt-2 sm:mt-0">
        <label htmlFor="status" className="block text-sm font-medium leading-6 text-gray-900">
          Status
        </label>
        <div className="mt-2">
          <select
            id="status"
            name="status"
            className="px-1 h-8 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            value={filterQuery.status}
            onChange={(e) => setFilterQuery({ ...filterQuery, status: e.target.value })}
          >
            <option value="">All</option>
            <option value="Pending">Pending</option>
            <option value="In Progress">In Progress</option>
            <option value="Resolved">Resolved</option>
            <option value="Canceled">Canceled</option>
            <option value="Cancel Requested">Cancel Requested</option>
          </select>
        </div>
      </div>
      {/* reset filter button */}
      <div className="sm:col-span-1 sm:ml-2 mt-2 sm:mt-0">
        <button
          type="button"
          className="h-8 inline-flex w-full justify-center items-center rounded-md bg-gray-200 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={handleResetFilter}
        >
          Reset
        </button>
      </div>
    </div>
  )
}

// action dropdown 
function ActionDropdown({itemId, setTickets, setLoading}) {
  const RequestCancellation = () => {
    // prompt confirmation
    if (!window.confirm("Are you sure you want to cancel this ticket?")) {
      return;
    }
    
    let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/ticket/client-cancel";
    let submitBody = {
      ticketID: itemId,
    }

    fetch(url, {
      method: "POST",
      body: JSON.stringify(submitBody),
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response.ok) {
        alert("Ticket has been cancelled")
        fetchTickets(setTickets, setLoading);
        return response.json();
      } else {
        console.log(response.text());
        throw new Error("Error");
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });

  }

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <MenuButton className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
          <span className="sr-only">Open options</span>
          <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
        </MenuButton>
      </div>

      <MenuItems
        transition
        className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
      >
        <div className="py-1">
          <MenuItem>
            {({ focus }) => (
              <a
                onClick={RequestCancellation}
                className={classNames(focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm cursor-pointer')}
              >
                Cancel Ticket
              </a>
            )}
          </MenuItem>
          {/* <MenuItem>
            {({ focus }) => (
              <a
                href="#"
                className={classNames(focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm')}
              >
                Support
              </a>
            )}
          </MenuItem> */}
          
        </div>
      </MenuItems>
    </Menu>
  )
}




