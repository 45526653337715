import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// redirect users to /home when this page is accessed
const IndexPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        window.location.href = '/home';
    }, []);

    return null;
};

export default IndexPage;