// src/Onboarding.js
import React, { useState, useRef, useEffect } from 'react';
import GlobalVars from '../../Config';

const Onboarding = () => {
  const [displayName, setDisplayName] = useState('');
  const [workPhoneNumber, setWorkPhoneNumber] = useState('');
  const [companyName, setCompanyName] = useState('');
  const submitBtnRef = useRef(null);

  const handleSubmit = (event) => {
    event.preventDefault();

    submitBtnRef.current.disabled = true;
    submitBtnRef.current.innerText = 'Submitting...';

    // Handle form submission logic here
    console.log({ displayName, workPhoneNumber, companyName });
    // create fetch request to (/api/v1/users/)
    fetch(`${GlobalVars.BACKEND_DOMAIN}/api/v1/users/manage`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            firebaseID: localStorage.getItem('firebaseID'),
            phone: workPhoneNumber,
            email: localStorage.getItem('userEmail'),
            displayName: displayName,
            company: companyName,
        }),
        })
        .then((response) => response.json())
        .then((data) => {
            alert('User created successfully! We will process your application within one day. You can start submitting tickets on our platform now.');
            // Redirect to dashboard
            window.location.href = '/';
        })
        .catch((error) => {
            alert("An error occurred. Please try again.");
            console.error('Error:', error);
            submitBtnRef.current.disabled = false;
            submitBtnRef.current.innerText = 'Submit';
        });
  };

  const [langList, setLangList] = useState([
    { name: 'English', code: 'en', href:"#Weglot-en" },
    { name: '繁體中文', code: 'tw', href:"#Weglot-tw" },
    { name: '简体中文', code: 'zh', href:"#Weglot-zh" },
  ]);

  // set local storage for language
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('language'))
  const [displayLangName, setDisplayLangName] = useState('English')  
  useEffect(() => {
    if (localStorage.getItem('language') === null) {
      localStorage.setItem('language', 'en')
    } else {
      setSelectedLanguage(localStorage.getItem('language'))
      
    }

  }, [])

  // update displayName when selectedLanguage changes
  useEffect(() => {
    try {
      const language = langList.find((item) => item.code === selectedLanguage);
      if (language && language.name) {
        window.UpdateLang(language.code)
        setDisplayLangName(language.name);
      } else {
        throw new Error('Language name is undefined');
      }
    } catch (error) {
      console.error(error);
    }
  }, [selectedLanguage])

  // handle language switching 
  const handleLanguageSwitch = (e) => {
    setSelectedLanguage(e.target.value)
    localStorage.setItem('language', e.target.value)
  }

  return (
    <>
      <div className='min-h-screen flex items-center justify-center bg-gray-100'>
        <div className='bg-white p-8 rounded-lg shadow-md w-full max-w-md'>
          <h2 className='text-2xl font-bold mb-6'>Looks like you are new here</h2>
          <p
              className='text-gray-700 mb-6'
          >Let's get some details to set up your account.</p>
          <form onSubmit={handleSubmit}>
            <div className='mb-4'>
              <label className='block text-gray-700 mb-2' htmlFor='displayName'>
                Display Name
              </label>
              <input
                id='displayName'
                className='w-full p-2 border border-gray-300 rounded'
                type='text'
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
                required
              />
            </div>
            <div className='mb-4'>
              <label className='block text-gray-700 mb-2' htmlFor='workPhoneNumber'>
                Work Phone Number
              </label>
              <p
                  className='text-gray-500 mb-2 text-xs'
              >
                  This will help us to reach you out in case of we need clarification on your submission.
              </p>
              <input
                id='workPhoneNumber'
                className='w-full p-2 border border-gray-300 rounded'
                type='number'
                value={workPhoneNumber}
                onChange={(e) => setWorkPhoneNumber(e.target.value)}
              />
            </div>
            <div className='mb-6'>
              <label className='block text-gray-700 mb-2' htmlFor='companyName'>
                Company Name
              </label>
              <input
                id='companyName'
                className='w-full p-2 border border-gray-300 rounded'
                type='text'
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                required
              />
            </div>
            <button
              type='button'
              className='w-full py-2 px-4 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-600'
              onClick={handleSubmit}
              ref={submitBtnRef}
            >
              Submit
            </button>
          </form>
        </div>
      </div>
      {/* language switcher at the upper right */}
      <div className='absolute top-0 right-0 m-4' id="lang-switcher">
        <select
          className='p-2 border border-gray-300 rounded'
          onChange={handleLanguageSwitch}
          defaultValue={localStorage.getItem('language')}
        >
          {langList.map((lang) => (
            <option key={lang.code} value={lang.code}>
              {lang.name}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

export default Onboarding;