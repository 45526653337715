import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate, Outlet, useNavigate } from 'react-router-dom';

import SubmitTicket from './Pages/SubmitTicket';
import HomePage from './Pages/Home';
import Authentication from './Pages/Authentication';
import Signout from './Pages/Signout';
import LocationUserRelationship from './Pages/LocationUserRelationship';
import TicketList from './Pages/TicketList';
import Onboarding from './Pages/Onboarding';
import Index from './Pages/Index';
// import WeglotInitializer from './Components/WeglotInitializer';

import app from './firebase';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from 'react';
// Initialize Firebase
const auth = getAuth(app);

function AuthCheck({ children }) {
  const navigate = useNavigate();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in.
        // ...
      } else {
        // User is signed out.
        // Redirect to login page
        navigate("/auth");
      }
    });
  }, [navigate]);

  // Wrap children with Outlet to render nested routes
  return <>{children ? children : <Outlet />}</>;
}

// Define a wrapper component for your protected routes
function ProtectedRoute({ children }) {
  
  if (localStorage.getItem('firebaseToken')) {
    return (
      <>
        <AuthCheck>
          {children}
        </AuthCheck>
      </>
    );
  } else {
    return <Navigate to="/auth" />;
  }
}

// Company route (only @goldennews.com.hk emails are allowed)
// function CompanyRoute({ children }) {
//   if (localStorage.getItem('firebaseToken')) {
//     const userEmail = localStorage.getItem('userEmail');
//     if (userEmail && userEmail.includes('@goldennews.com.hk')) {
//       return (
//         <>
//           <AuthCheck>
//             {children}
//           </AuthCheck>
//         </>
//       );
//     } else {
//       alert("You are not allowed to access this page! Please login with your goldennews.com.hk email.");
//       return <Navigate to="/" />;
//     }
//   } else {
//     console.log("No token found");
//     alert("You are not allowed to access this page!")
//     return <Navigate to="/" />;
//   }
// }

function CompanyRoute({ children }) {
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (checked) return;

    const firebaseToken = localStorage.getItem('firebaseToken');
    const userEmail = localStorage.getItem('userEmail');

    if (!firebaseToken) {
      alert("You are not allowed to access this page before you login. We will redirect you to the home page now. 請先登入。我們將會將你導向主頁。");
      navigate('/');
    } else if (!(userEmail && userEmail.includes('@goldennews.com.hk'))) {
      alert("This functionality is restricted to System Administrators' email addresses only. We will redirect you to the home page now. 此功能只限系統管理員使用。我們將會將你導向主頁。");
      navigate('/');
    } else {
      setChecked(true);
    }
  }, [checked, navigate]);

  return (
    <>
      {children}
    </>
  );
}


function App() {

  return (
    <Router>
      {/* <WeglotInitializer /> */}
      <Routes>
        <Route path="/" element={<Index />} />``
        <Route path="/auth" element={<Authentication />} />
        <Route path="/submit" element={<SubmitTicket />} />
        <Route path="/home" element={<HomePage />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/signout" element={<Signout />} />
          <Route path="/tickets" element={<TicketList />} />
        </Route>
        {/* <Route element={<CompanyRoute />}>
          <Route path="/location-user-relationship" element={<LocationUserRelationship />} />
        </Route> */}
        <Route path="/location-user-relationship" element={<CompanyRoute><LocationUserRelationship /></CompanyRoute>} />
        <Route path="/onboarding" element={<Onboarding />} />
      </Routes>
    </Router>
  );
}

export default App;
