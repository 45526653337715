import { initializeApp } from "firebase/app";
import { getAuth, signOut } from "firebase/auth";
import app from '../../firebase';

export default function Signout() {
    const auth = getAuth(app);
    signOut(auth).then(() => {
        // clear all local storage
        localStorage.clear();


        // Sign-out successful.
        console.log("Sign-out successful");
        // Redirect to login page
        window.location.href = "/";
    }).catch((error) => {
        // An error happened.
        console.error(error);
    });
    return (
        <div>
            Signing out...
        </div>
    )

}