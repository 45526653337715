// globals.js
const GlobalVars = {
    BACKEND_DOMAIN: "https://ticket-service-dot-otter-and-seal.df.r.appspot.com",
    // BACKEND_DOMAIN: "http://127.0.0.1:5000",
    AIASSIST_DOMAIN: "https://ai-assistant-service-dot-otter-and-seal.df.r.appspot.com",
    // AIASSIST_DOMAIN: "http://127.0.0.1:5002",
    firebase: {
        apiKey: "AIzaSyCZ6HpNl7kZwevmiwnEcgf4RrnHO51LDtA",
        authDomain: "otter-and-seal.firebaseapp.com",
        projectId: "otter-and-seal",
        storageBucket: "otter-and-seal.appspot.com",
        messagingSenderId: "433587317721",
        appId: "1:433587317721:web:dd41293420b7d7f19047c8"
    },
    firebase_publick_fcm_key: "BFh0W_PFnkeAQmnBukFQ4vv-fG-Tx3fIHXQhpgL2pAbUhjfqLIHkMARWviL74iQ5gtKJG_hIrGCZOBt1ZP9Yi3s",
    productType: [
        { "label": "CABD 公共天線系統", "value": "CABD" },
        { "label": "CCTV 閉路電視", "value": "CCTV" },
        { "label": "Data Switch 數據交換機", "value": "Data Switch" },
        { "label": "Door Lock 門鎖", "value": "Door Lock" },
        { "label": "E-Casual 電子便捷", "value": "E-Casual" },
        { "label": "Email 電子郵件", "value": "Email" },
        { "label": "Firewall 防火牆", "value": "Firewall" },
        { "label": "Front Office/Property Management System (PMS) 酒店前台管理系統", "value": "Front Office System/Property Management System (PMS)" },
        { "label": "Internet Gateway 網絡閘道", "value": "Internet Gateway" },
        { "label": "Kiosk 自助服務機", "value": "Kiosk" },
        { "label": "Network 網絡", "value": "Network" },
        { "label": "NSS Server 通知系統", "value": "NSS Server" },
        { "label": "POS 餐飲系統", "value": "POS" },
        { "label": "PC 個人電腦", "value": "PC" },
        { "label": "PABX 電話系統", "value": "PABX" },
        { "label": "Telephone Set 電話機", "value": "Telephone Set" },
        { "label": "TV 電視", "value": "TV" },
        { "label": "UPS 不間斷電源", "value": "UPS" },
        { "label": "Vending Machine 自助售貨機", "value": "Vending Machine" },
        { "label": "Voice Logger 語音記錄器", "value": "Voice Logger" },
        { "label": "Website 網站", "value": "Website" },
        { "label": "WiFi/AP 無線網絡", "value": "WiFi/AP" },
        { "label": "Voice Mail System 語音信箱系統", "value": "i-Message Voice Mail System" },
        { "label": "iPanel/iSignage 電子廣告板", "value": "i-Panel" },
        { "label": "Call Accounting System 計費系統", "value": "i-interface Call Accounting System" },
        { "label": "Other Products & Services 其他產品及服務", "value": "Other Products & Services" }
    ]
};

export default GlobalVars;
