import ApplicationShell from "../../Components/ApplicationShell/ApplicationShell";
import illustration from "../../Assets/An_illustration_depicting_a_person_working_at_a_call_center_providing_technical_support_with_a_computer_and_a_earpiece_talking_to_a_person_in_the_phone.png";

export default function HomePage() {

    return (
        <>
            <ApplicationShell>
                <div
                    className="flex flex-col items-center"
                >
                    <img src={illustration} alt="An illustration depicting a person working at a call center providing technical support with a computer and a earpiece talking to a person in the phone" 
                        className="max-h-[200px] w-1/2 mx-auto mt-10 mb-10 rounded-xl object-contain"
                    />
                    <h2
                        className="text-3xl text-center font-bold"
                    >
                        How can we help you today?
                    </h2>
                    <button
                        className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded-full mt-10 w-[70%] duration-300"
                        onClick={() => window.location.href = "/submit"}
                    >
                        Submit a ticket
                    </button>
                    <span
                        className="mt-5 text-gray-500"
                    >and we will get back to you as soon as possible.</span>
                </div>
            </ApplicationShell>
        </>
    )
}