import { initializeApp } from "firebase/app"
import { useEffect } from "react";
import * as firebaseui from 'firebaseui';
import { getAuth, EmailAuthProvider } from 'firebase/auth';
import app from '../../firebase';
import GlobalVars from "../../Config";
import compangLogo from "../../Assets/Company Logo.png";

import "./Login.css";

// Initialize Firebase
const auth = getAuth(app);

export default function Authentication() {

    document.title = "Authentication"
    
    // useEffect(() => {
    //     // load stylesheet (<link type="text/css" rel="stylesheet" href="https://www.gstatic.com/firebasejs/ui/6.0.1/firebase-ui-auth.css" />)
    //     const link = document.createElement('link');
    //     link.type = 'text/css';
    //     link.rel = 'stylesheet';
    //     link.href = 'https://www.gstatic.com/firebasejs/ui/6.0.1/firebase-ui-auth.css';
    //     document.head.appendChild(link);

    //     // load script (<script src="https://www.gstatic.com/firebasejs/ui/6.0.1/firebase-ui-auth.js"></script>
    //     const script = document.createElement('script');
    //     script.src = 'https://www.gstatic.com/firebasejs/ui/6.0.1/firebase-ui-auth.js';
    //     document.body.appendChild(script);

    // }, []);



    const uiConfig = {
        signInFlow: 'popup',
        signInOptions: [
            {provider: EmailAuthProvider.PROVIDER_ID, requireDisplayName: false},
        ],
        callbacks: {
            signInSuccessWithAuthResult: (authResult) => {
                // Get ID token
                authResult.user.getIdToken(true).then(function(idToken) {
                    
                    // store to session storage
                    localStorage.setItem('firebaseToken', idToken);

                    // store users email to local storage
                    localStorage.setItem('userEmail', authResult.user.email);

                    // store user id to local storage
                    localStorage.setItem('firebaseID', authResult.user.uid);

                    
                    // Check if it's a new user
                    if (authResult.additionalUserInfo.isNewUser) {
                        // redirect to onboarding page
                        window.location.href = "/onboarding";
                    } else {
                        // redirect to sales list
                        window.location.href = "/";
                    }
                    

                }).catch(function(error) {
                    // Handle error
                    console.error(error);
                });
                return false; // This prevents the page from reloading after login
                
            }
        }
    };

    useEffect(() => {
        const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth);
        ui.start('#firebaseui-auth-container', uiConfig);
    }, []);
    


    return (
        <div
            className="flex items-center flex-col justify-center h-screen bg-gray-100 relative"
        >   
            <div
                className="bg-white rounded-lg shadow-lg pt-16 pb-10 max-w-[90vw]"
            >   
                <div
                    className="mb-8"
                >
                    <img src={compangLogo} alt="company logo" className="mx-auto h-12" />
                </div>
                <div id="firebaseui-auth-container" className="max-w-[100%] w-[500px]" ></div>
            </div>
            <div
                className="absolute bottom-0 w-full text-center text-gray-400"
            >
            © Golden News Enterprises Limited 1993-2024. All rights reserved.
            </div>
        </div>
    )
}