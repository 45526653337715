import ApplicationShell from "../../Components/ApplicationShell";
import { useState, useEffect, Fragment, useRef } from "react";
import GlobalVars from "../../Config";
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import SelfDisappearNotification from "../../Components/SelfDisappearNotification";

export default function LocationUserRelationship() {
    // set title 
    document.title = "Location User Relationship";

    const [openSlideOver, setOpenSlideOver] = useState(false)
    const [editLocationId, setEditLocationId] = useState(null)

    const [openSelfDisappearNotification, setOpenSelfDisappearNotification] = useState(false)
    const [notificationTitle, setNotificationTitle] = useState("Success!")
    const [notificationBody, setNotificationBody] = useState("Operation Successful")

    const [locList, setLocList] = useState([]);
    const [userLocList, setUserLocList] = useState([]);

    return (
        <div>
            <ApplicationShell>
                <PageHeadings setOpenSlideOver={setOpenSlideOver} />
                <OutstandingNonAssociatedUserList />
                <LocationList 
                    setOpenSlideOver={setOpenSlideOver} 
                    setEditLocationId={setEditLocationId} 
                    locList={locList}
                    setLocList={setLocList}
                    userLocList={userLocList}
                    setUserLocList={setUserLocList}
                />
            </ApplicationShell>
            <EditRelationship 
                open={openSlideOver} 
                setOpen={setOpenSlideOver} 
                editLocationId={editLocationId} 
                setNotificationBody={setNotificationBody}
                setNotificationTitle={setNotificationTitle}
                setOpenSelfDisappearNotification={setOpenSelfDisappearNotification}
                setLocList={setLocList}
                userLocList={userLocList}
                setUserLocList={setUserLocList}

            />
            <SelfDisappearNotification 
                title={notificationTitle} 
                body={notificationBody} 
                show={openSelfDisappearNotification} 
                setShow={setOpenSelfDisappearNotification}
            
            />
        </div>
    );
}   


// page headings
function PageHeadings({setOpenSlideOver}) {
    return (
      <div className="md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            User Management
          </h2>
        </div>
        <div className="mt-4 flex md:ml-4 md:mt-0">
          <a
            type="button"
            className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            href="https://job-management-portal-dot-otter-and-seal.df.r.appspot.com/location-list"
            target="_blank"
          >
            Add Location
          </a>
          <button
            type="button"
            className="ml-3 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Add User
          </button>
        </div>
      </div>
    )
  }



// location list 
function LocationList({
    setOpenSlideOver, 
    setEditLocationId, 
    locList, 
    setLocList,
    userLocList,
    setUserLocList
}) {
    
    // handle filter input
    const [filterInput, setFilterInput] = useState({
        "location": "",
        "email": ""
    })

    // fetch current location list 
    useEffect(() => {
        loadLocationList(setLocList, filterInput, userLocList, setUserLocList);
    }, [])

    const debouncedLoadLocationList = debounce((setLocList, filterInput, userLocList, setUserLocList) => {
        loadLocationList(setLocList, filterInput, userLocList, setUserLocList);
    }, 300); // Adjust the 300ms delay as needed

    useEffect(() => {
        debouncedLoadLocationList(setLocList, filterInput, userLocList, setUserLocList);
    }, [filterInput])
   

    return (
        <div>
            <div className="mt-8 flow-root overflow-hidden">
                {/* filter input */}
                <div className="flex items-end">
                    <div className="mr-2">
                        <label 
                            htmlFor="filter"
                            className="text-sm font-semibold text-gray-900"
                        >
                            Location
                        </label>
                        <input
                            type="text"
                            name="filter"
                            id="filter"
                            className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Filter by location"
                            value={filterInput.location}
                            onChange={(e) => setFilterInput({...filterInput, location: e.target.value})}
                        />
                    </div>
                    <div className="">
                        <label 
                            htmlFor="filter"
                            className="text-sm font-semibold text-gray-900"
                        >
                            User's Email
                        </label>
                        <input
                            type="text"
                            name="filter"
                            id="filter"
                            className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Filter by email"
                            value={filterInput.email}
                            onChange={(e) => setFilterInput({...filterInput, email: e.target.value})}
                        />
                    </div>
                    <button
                        type="button"
                        onClick={() => {
                            setFilterInput({
                                "location": "",
                                "email": ""
                            })
                        }}
                        className="ml-2 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-gray-300 hover:ring-gray-400 h-[36px]"
                    >
                        Reset Filter
                    </button>
                </div>
                <div className="mx-auto max-w-7xl">
                <table className="w-full text-left">
                    <thead className="bg-white">
                        <tr>
                            <th scope="col" className="relative isolate py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">
                            Location
                            <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-b-gray-200" />
                            <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-b-gray-200" />
                            </th>
                            <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                            >
                            Number of User
                            </th>
                            {/* <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
                            >
                            Email
                            </th> */}
                            {/* <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Role
                            </th> */}
                            <th scope="col" className="relative py-3.5 pl-3">
                                <span className="sr-only">Edit</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                    {locList.map((item) => (
                        <tr key={item.location}>
                        <td className="relative py-4 pr-3 text-sm font-medium text-gray-900">
                            {item.displayName}
                            <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                            <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                        </td>
                        <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{item.users}</td>
                        {/* <td className="hidden px-3 py-4 text-sm text-gray-500 md:table-cell">{person.email}</td> */}
                        {/* <td className="px-3 py-4 text-sm text-gray-500">{person.role}</td> */}
                        <td className="relative py-4 pl-3 text-right text-sm font-medium">
                            <a 
                                onClick={() => {
                                    setOpenSlideOver(true)
                                    setEditLocationId(item.value)
                                }}
                                className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                            >
                            Edit<span className="sr-only">, {item.value}</span>
                            </a>
                        </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                </div>
            </div>
        </div>
    )
}

const loadLocationList = (setLocList, filterInput={}, userLocList, setUserLocList) => {
    let fullLocList = [];
    let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/location?isActive=true";

    if (filterInput.hasOwnProperty("location") && filterInput.location !== "") {
        url += "&locQuery=" + filterInput.location;
    }

    fetch(url)
        .then(response => response.json())
        .then(data => {

            // set label to location 
            data.forEach(item => {
                item['location'] = item['label'];
                
                // set display name to location
                item['displayName'] = item['label'];
                if (item.hasOwnProperty("langVar")) {
                    if (item['langVar'].hasOwnProperty('tc')) {
                        item['displayName'] += " (" + item['langVar']['tc'] + ")";
                    }
                }
            });

            fullLocList = data;
            if (userLocList.length === 0) {
                fetch(GlobalVars.BACKEND_DOMAIN + "/api/v1/location-user")
                    .then(response => response.json())
                    .then(data => {
                        
                        setUserLocList(data);
                        mergeLocationUserList(data, filterInput, fullLocList, setLocList);
                        
                    });
            } else {
                mergeLocationUserList(userLocList, filterInput, fullLocList, setLocList);
            }
        });
}

// merge location list with user list
function mergeLocationUserList(userLocList, filterInput, fullLocList, setLocList) {
    let emailQuery = filterInput.email;
    let mergedData;

    // if email query is empty, show all users. 
    if (!emailQuery || emailQuery.trim() === '') {
        // combine fullLocList with user count
        mergedData = fullLocList.map(item => {
            let users = userLocList.filter(user => user.locationName === item.location);
            item['users'] = users.length;
            return item;
        });
    } else {
        mergedData = fullLocList.map(item => {
            let users = userLocList.filter(user => user.locationName === item.location);

            let userCount = users.filter(user => user.email && user.email.toLowerCase().includes(emailQuery.toLowerCase())).length;
            item['users'] = userCount;
            return item;

        }).filter(item => item.users > 0);
    }

    // sort list by alphabetical order
    mergedData.sort((a, b) => a.location.localeCompare(b.location));
    
    // set the list
    setLocList(mergedData);
}


function EditRelationship({
    open, 
    setOpen, 
    editLocationId, 
    setNotificationTitle, 
    setNotificationBody, 
    setOpenSelfDisappearNotification, 
    setLocList,
    userLocList,
    setUserLocList
}) {
    const [emailList, setEmailList] = useState([]);

    useEffect(() => {
        // skip if editLocationId is null
        if (!editLocationId) return;

        setEmailList([]);

        // fetch email list for specific loaction 
        fetch(GlobalVars.BACKEND_DOMAIN + "/api/v1/location-user?locationID=" + editLocationId)
            .then(response => response.json())
            .then(data => {
                console.log(data);
                setEmailList(data);
            });
    }, [editLocationId])

    const saveBtn = useRef(null);
    const handleSave = () => {
        
        // prompt confirmation 
        if (!window.confirm("Are you sure you want to save the changes?")) return;

        // check each email in emailList to be valid email
        for (let i = 0; i < emailList.length; i++) {
            if (!emailList[i].label.includes("@") || !emailList[i].label.includes(".")) {
                alert("Invalid email address: " + emailList[i].label);
                return;
            }
        }

        // deactivate button and change text to "Saving..."
        saveBtn.current.innerText = "Saving...";
        saveBtn.current.disabled = true;

        // save email list to backend
        fetch(GlobalVars.BACKEND_DOMAIN + "/api/v1/location-user", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                locationID: editLocationId,
                emailList: emailList.map(item => item.label)
            })
        })
        .then(response => response.json())
        .then(data => {
            setNotificationTitle("Success!")
            setNotificationBody("Email list updated successfully")
            setOpenSelfDisappearNotification(true)

            // reset button text and enable button
            saveBtn.current.innerText = "Save";
            saveBtn.current.disabled = false;

            // reload location list
            loadLocationList(setLocList, {}, userLocList, setUserLocList);

            // close the slide over
            setOpen(false);

        })
        .catch(error => {
            console.error("Error:", error);
        });
    }

    return (
        <Transition.Root show={open} as={Fragment}>
        <Dialog className="relative z-50" onClose={setOpen}>
            <div className="fixed inset-0" />

            <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                        <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
                        <div className="px-4 sm:px-6">
                            <div className="flex items-start justify-between">
                            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                                Edit Relationship
                            </Dialog.Title>
                            <div className="ml-3 flex h-7 items-center">
                                <button
                                type="button"
                                className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                onClick={() => setOpen(false)}
                                >
                                <span className="absolute -inset-2.5" />
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                            </div>
                            </div>
                        </div>
                            <div className="relative mt-6 flex-1 px-4 sm:px-6">
                                {/* list out individual email with an input element (type should be email) */}
                                {emailList.map((item) => (
                                    <div key={item.email} className="flex items-center justify-between py-3">
                                        <input
                                            type="email"
                                            className="px-2 mr-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            placeholder="you@example.com"
                                            defaultValue={item.label}
                                            onChange={(e) => {
                                                setEmailList(emailList.map(email => {
                                                    if (email.label === item.label) {
                                                        return {label: e.target.value}
                                                    }
                                                    return email;
                                                }))
                                            }}
                                        />
                                        <button
                                            type="button"
                                            className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-gray-300 hover:ring-gray-400 hover:bg-red-500"
                                            onClick={() => {
                                                // delete email from emailList
                                                setEmailList(emailList.filter(email => email.label !== item.label))
                                            }}
                                        >
                                            Delete
                                        </button>
                                    </div>
                                ))}
                                {/* an action button for creating new email relation */}
                                <div>
                                    <button
                                        type="button"
                                        className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-gray-300 hover:ring-gray-400"
                                        onClick={() => {
                                            setEmailList([...emailList, {label: ""}])
                                        }}
                                    >
                                        Add Another Email
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-shrink-0 justify-end px-4 py-4">
                        <button
                            type="button"
                            className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
                            onClick={() => setOpen(false)}
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            className="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                            onClick={handleSave}
                            ref={saveBtn}
                        >
                            Save
                        </button>
                        </div>
                    </div>
                    </Dialog.Panel>
                </Transition.Child>
                </div>
            </div>
            </div>
        </Dialog>
        </Transition.Root>
    )
}


// debounce function
function debounce(func, wait) {
    let timeout;
    return function(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}

// Outstanding list of non associated user
function OutstandingNonAssociatedUserList() {
    const [people, setPeople] = useState([]);

    // query for users without location
    useEffect(() => {
        fetch(GlobalVars.BACKEND_DOMAIN + "/api/v1/user/non-associated-users")
            .then(response => response.json())
            .then(data => {
                setPeople(data);
            });
    }, [])

    return (
        <>
        {people.length > 0 && ( // only show if there are users without location}
            <div className="mt-8 max-w-7xl bg-slate-200 rounded p-2">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">User without Associated Locations</h1>
                    <p className="mt-2 text-sm text-gray-700">
                        The following users are not associated with any location. Please assign them to a location.
                    </p>
                    </div>
                </div>
                <div className="mt-8 flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                            <tr>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                    Name
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Email
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Company
                                </th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                            {people.map((person) => (
                            <tr key={person.email}>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                {person.displayName}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.meta["email"]}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.meta["company"]}</td>
                            </tr>
                            ))}
                        </tbody>
                        </table>
                    </div>
                    </div>
                </div>
            </div>
        )}
        </>
    )

}

